.slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.slider {
    display: flex;
    overflow: hidden;
    width: 100%;
}

.slides {
    display: flex;
    transition: transform 0.3s ease-in-out;
    height: 15.5rem;
}

.slide {
    flex: 0 0 100%;
    height: 100%;
    /* padding: 7% 0; */
    overflow: hidden;
}
