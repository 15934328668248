.glow-container {
    --glow-line-color: #fff;
    --glow-line-thickness: 0.125rem;
    --glow-blur-color: #fff;
    --glow-blur-size: 0.375rem;
    --animation-speed: 0.5s;
    --pause-duration: 1.5s;
    --animation-delay: 0.6s;
    --total-duration: calc(var(--animation-speed) + var(--pause-duration));
    /* used for calculation. do not change */
    --container-offset: 5rem;

    position: absolute;
    top: calc(var(--container-offset) / -2);
    left: calc(var(--container-offset) / -2);
    width: calc(100% + var(--container-offset));
    height: calc(100% + var(--container-offset));
    pointer-events: none;
    /* opacity: 0; */
}

.bounce {
    --bounce-height: -0.75rem;
    --bounce-landing-offset: 0.5rem;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

.glow-blur,
.glow-line,
.glow-blur-2,
.glow-line-2 {
    width: calc(100% - var(--container-offset));
    height: calc(101% - var(--container-offset));
    x: calc(var(--container-offset) / 2);
    y: calc(var(--container-offset) / 2);
    /* rx doesn't work on safari */
    /* rx: 1.213rem; */
    fill: transparent;
    stroke: #000;
    stroke-width: 0.25rem;
    stroke-dasharray: 240 830;
    stroke-dashoffset: 0;

    animation: dashOffsetAnimation var(--total-duration) linear infinite,
        glowVisibility var(--total-duration) linear infinite;
}

.glow-90-ball {
    stroke-dasharray: 280 720;
    animation: dashOffsetAnimation90 var(--total-duration) linear infinite,
        glowVisibility var(--total-duration) linear infinite;
}

.glow-line,
.glow-line-2 {
    opacity: 0;
    stroke: var(--glow-line-color);
    stroke-width: var(--glow-line-thickness);
}

.glow-blur,
.glow-blur-2 {
    opacity: 0;
    /* filter: blur() doesn't work on safari */
    /* filter: blur(var(--glow-blur-size)); */
    stroke: var(--glow-blur-color);
    stroke-width: var(--glow-blur-size);
}

.glow-blur-2,
.glow-line-2 {
    animation-delay: var(--animation-delay);
}

.oneTG-glow {
    animation: textGlow 1s ease-in-out infinite alternate;
}

.oneTG-BINGO-glow {
    animation: textGlowBINGO 0.25s ease-in-out infinite alternate,
        pauseEffect 3s ease-in-out 1.5s infinite;
}

/* sequencial animation for BINGO overlay */
.oneTG-B-glow {
    animation: glowAndPause 3s ease-in-out 1.75s infinite;
}

.oneTG-I-glow {
    animation: glowAndPause 3s ease-in-out 2s infinite;
}

.oneTG-N-glow {
    animation: glowAndPause 3s ease-in-out 2.25s infinite;
}

.oneTG-G-glow {
    animation: glowAndPause 3s ease-in-out 2.5s infinite;
}

.oneTG-O-glow {
    animation: glowAndPause 3s ease-in-out 2.75s infinite;
}

@keyframes textGlow {
    from {
        text-shadow: 0 0.125rem #250c61, 0 0.063rem #fff;
    }
    to {
        text-shadow: 0 0.063rem #250c61, 0 0.063rem #fff, 0 0 0.375rem #fff,
            0 0 0.5rem #f7fc76;
    }
}

@keyframes textGlowBINGO {
    0% {
        text-shadow: 0 0.125rem #000, 0 0.063rem #fff;
    }
    100% {
        text-shadow: 0 0 0.063rem #fff, 0 0 0.375rem #fff, 0 0 0.5rem #f7fc76;
    }
}

@keyframes pauseEffect {
    0% {
        text-shadow: none;
    }
}

@keyframes glowAndPause {
    0%,
    2% {
        text-shadow: 0 0.125rem #000, 0 0.063rem #fff;
    }
    6% {
        text-shadow: 0 0 0.063rem #fff, 0 0 0.375rem #fff, 0 0 0.5rem #f7fc76;
    }
    10%,
    100% {
        text-shadow: 0 0.125rem #000, 0 0.063rem #fff;
    }
}

@keyframes dashOffsetAnimation {
    0% {
        stroke-dashoffset: 0;
    }
    33.33% {
        stroke-dashoffset: -830; /* Active phase ends at 33.33% */
    }
    100% {
        stroke-dashoffset: -830; /* Maintain the final state during the pause */
    }
}

@keyframes dashOffsetAnimation90 {
    0% {
        stroke-dashoffset: 0;
    }
    33.33% {
        stroke-dashoffset: -720; /* Active phase ends at 33.33% */
    }
    100% {
        stroke-dashoffset: -720; /* Maintain the final state during the pause */
    }
}

@keyframes glowVisibility {
    0%,
    33.33% {
        opacity: 1;
    }
    100% {
        opacity: 0; /* Maintain the paused state */
    }
}

@keyframes bounce {
    0%,
    60%,
    100% {
        transform: scale(1, 1) translateY(0);
    }
    10%,
    50% {
        transform: scale(1.1, 0.8) translateY(var(--bounce-landing-offset));
    }
    30% {
        transform: scale(1, 1.1) translateY(var(--bounce-height));
    }
}
