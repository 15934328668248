html {
    height: 100%;
    box-sizing: border-box;
}

/* 2xl */
@media only screen and (min-width: 1536px) {
    body {
        margin: 0 18.75rem;
    }
}

/* xl */
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
    body {
        margin: 0 12.5rem;
    }
}

/* lg */
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    body {
        margin: 0 6.25rem;
    }
}

/* md */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body {
        margin: 0 3.125rem;
    }
}

/* sm */
@media only screen and (min-width: 640px) and (max-width: 767) {
    body {
        margin: 0;
    }
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

@font-face {
    font-family: 'Signika-Medium';
    src: url('../fonts/Signika/Signika-Medium.ttf');
}

@font-face {
    font-family: 'Signika-SemiBold';
    src: url('../fonts/Signika/Signika-SemiBold.ttf');
}

@font-face {
    font-family: 'Signika-Bold';
    src: url('../fonts/Signika/Signika-Bold.ttf');
}

@font-face {
    font-family: 'Mitr';
    src: url('../fonts/Mitr/Mitr-SemiBold.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/Roboto/Roboto-Bold.ttf');
}
